@font-face {
  font-family: 'Roboto';
  src: url("../Roboto-Regular.ttf");
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family:'Roboto';
  font-style: normal;
  /* background: #E5E5E5; */
}

:root {
  --main-color: #AAD152;
}
