.textTitleAdd {
  font-size: 20px;
  font-weight: 600;
}

.btnAdd {
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
  width: 10%;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="file"] {
  display: none;
}

.imageDivUser{
  background-color:#AAD152;
  width: 30%;
  border-radius: 50%;
  padding: 2%;
  margin: auto;
}

.plusIconImg{
  font-size: 30px;
  left:36%;
  top:7%;
  color: gray;
  cursor: pointer;
}
